
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import {
		AutoComplete,
		Button,
		Cascader,
		DatePicker,
		Form,
		FormItem,
		Modal,
		Option,
		Select,
		Upload, Input
	} from "view-design";
	import request from "@/request";
	import { Route } from "vue-router";
	import moment from "moment";
	import qs from "qs";
	import { CreateElement, VNode } from "vue";

	interface Tree {
		label:string,
		value:number,
		type:"system" | "category" | "device",
		loading?:boolean,
		children?:Tree[]
	}

	interface resolve {
		what:string,
		how:string,
		why:string
	}

	@Component({
		components: {
			AutoComplete,
			Button,
			Cascader,
			DatePicker,
			Form,
			FormItem,
			Modal,
			Option,
			Select,
			Upload, Input
		},
        filters: {
            getTimeString(value: Date | undefined): string {
                if (typeof value === "undefined") {
                    return "暂未指定";
                } else {
                    return moment(value).format("YYYY-MM-DD");
                }
            }
        }
	})
		export default class Distribution extends Vue {
            @Prop({ type: Array, default: () => ([]) }) readonly templateArray?:{ text:string, value:number }[];
            @Prop({ type: Array, default: () => ([]) }) readonly btnList?:{ value:("" | number), text:string }[];
            @Prop(Array) readonly device?:number[];
            @Prop(Array) readonly myspacedata?:any[];
            @Prop(Date) readonly deadline?:Date;
            @Prop(Number) readonly priority?:number;
            @Prop(Number) readonly template?:Number;
            @Prop(String) readonly description?:string;
            @Prop() readonly paramId:any;
            floorTree:Tree[] = [];
            deviceTree:Tree[] = [];
            resolve:string[] = [];
            reason:string[] = [];
            advice:string[] = [];
            deviceType:any = [];
            floorId:any="";
            roomId:any="";
            info = {
                // space: this.errorSpace,
                // device: this.device,
                // deadline: this.deadline,
                // priority: this.priority,
                // describe: this.description,
                // fileList: [],
                // template: this.template,
                how: "",
                why: "",
                space: [],
                device: [],
                deadline: "",
                priority: "",
                describe: "",
                fileList: [],
                template: ""
            };
            ruleValidate = {
                template: [{ required: true, message: "请选择", trigger: "change" }]
            }
		loading:boolean = false;
		readonly option = {
			disabledDate (date:Date):boolean {
				return date && date.valueOf() < Date.now();
			}
		};
		private resolveSource:resolve[] = [];
		// private readonly listener = this.$store.subscribe(mutation => {
		// 	if (mutation.type === "findRoom" && mutation.payload.path) {
		// 		this.info.space = mutation.payload.path;
		// 	}
		// });

		get buttons ():{ value:number | "", text:string }[] {
			let list = this.btnList || [];
			if (list.length > 0) {
				return list;
			} else {
				list.push({ value: "", text: "确认" });
				return list;
			}
		}

		loadDevice (item:Tree, callback:Function) {
			item.loading = true;
			let url:string;
			let params;
			if (item.type === "system") {
				url = "/device/devicecategory/";
				params = {
					DeviceType__mepsystemtype: item.value,
					DeviceType__Device__room__floor: this.$store.state.floorValue,
					DeviceType__Device__room: this.info.space ? this.info.space[ 2 ] : ""
				};
			} else {
				url = "/device/device2/";
				params = {
					devicetype__device_category: item.value,
					room: this.info.space ? this.info.space[ 2 ] : "",
					room__floor: this.$store.state.floorValue
				};
			}
			request({
				url,
				params
			}).then(({ data }) => {
				if (url === "/device/devicecategory/") {
					item.children = data.map((t:any):Tree => ({
						label: t.name,
						value: t.id,
						loading: false,
						type: "category",
						children: []
					}));
				} else {
					item.children = data.results.map((t:any):Tree => ({
						label: t.device_category_name + t.code,
						value: t.id,
						type: "device"
					}));
				}
				item.loading = false;
				callback();
			});
		}

		getCurrentDeviceTree () {
			request({
				url: "/device/mepsystemtype/",
				params: {
					DeviceType__Device__room: this.info.space ? this.info.space[ 2 ] : ""
				}
			}).then(({ data }) => {
				this.deviceTree = data.map((t:any) => ({
					label: t.name,
					value: t.id,
					type: "system",
					loading: false,
					children: []
				}));
			});
		}

		searchDescribe (text:string) {
			if (text === "") {
				this.resolve = [];
			} else {
				let regexp:RegExp = new RegExp(text);
				let set:Set<string> = new Set();
				let set2:Set<string> = new Set();
				let set3:Set<string> = new Set();
				this.resolveSource.forEach(t => {
					if (regexp.test(t.what)) {
						set.add(t.what);
						set2.add(t.why);
						set3.add(t.how);
					}
				});
				if (set.size === 0) {
					set.add("没有相关建议");
				}
				this.resolve = Array.from(set);
				this.reason = Array.from(set2);
				this.advice = Array.from(set3);
			}
		}

		focusToRoom (value:number[]) {
			this.roomId = "";
            this.floorId = "";
			if (value && value.length === 3) {
                this.roomId = value[ 2 ];
			}
            if (value && value.length >= 2) {
                this.floorId = value[1];
            }
            this.info.device = [];
            this.getDeviceType();
		}

		previewFile (file:any) {
			let url = file.response.data.fileurl;
			if (!file.response.data.image) {
				window.open(url);
			} else {
				const config = {
					render (h:CreateElement):VNode {
						return h("img", { attrs: { src: url }, staticStyle: { width: "100%" } });
					},
					width: 800
				};
				// @ts-ignore
				Modal.info(config);
			}
		}

		handleExceeded () {
			this.$Notice.error({ desc: "文件大小超出限制" });
		}

		setAdvice (value:string) {
			const temp = this.resolveSource.find(t => t.why === value);
			if (temp) {
				this.info.how = temp.how;
			}
		}

		close () {
			this.$Modal.confirm({
				content: "确认要关闭工单吗?",
				onOk: () => {
					this.loading = true;
					request({
						url: `/repair/rest/projectevent/${ this.paramId }/`,
						data: {
							status: 4
						},
						method: "PATCH"
					}).then(() => {
						this.$router.go(-1);
					});
				}
			});
		}

		submit () {
            if (!this.info.template) {
                this.$Message.info("请添加处理班组!");
                return;
            }
			this.loading = true;
			const deviceLength = this.info.device ? (this.info.device.length - 1) : -1;
			let data:any = {
				opt: "dealevent",
				eid: this.paramId,
				oid: "",
                describe: this.info.describe,
				// 弃用 [ [ "mepsystemtype", "devicetype", "device" ][ deviceLength ] ]: this.info.device ? this.info.device[ deviceLength ] : "",
				deadline: this.info.deadline ? moment(this.info.deadline).format("YYYY-MM-DD") : "",
				priority: this.info.priority,
				template: this.info.template,
				space: this.info.space,
				how: this.info.how,
				fileids: (this.$refs.upload as any).fileList.map((t:any) => t.response.data.docId)
			};
            let isCamera = this.info.device[ 0 ] == 11542;
            // 关联设备，或着摄像头
            data[isCamera ? "camera" : "device"] = this.info.device[ 1 ];
			request({
				url: "/repair/eventopt/",
				method: "post",
				data,
				transformRequest: [ function (data) {
					return qs.stringify(data, { encode: false, arrayFormat: "brackets" });
				} ]
			}).then(() => {
				// this.$router.go(-1);
                this.$emit("close");
			});
		}
        getDeviceType () {
            request("/device/devicetype/?format=json").then((data) => {
                let temp = [];
                data.data.forEach(val => {
                    temp.push({
                        value: val.id,
                        label: val.name,
                        children: [],
                        loading: false
                    });
                });
                this.deviceType = temp;
            });
        }
        loadDevicedata (item:Tree, callback:Function) {
			item.loading = true;
            // 监控，设备
            if (item.value == 11542) {
                 request(`/smt/rest/camera/?format=json&format=json&pagesize=999&room__floor=${ this.floorId }&room=${ this.roomId }`).then(({ data }) => {
                    item.children = data.results.map((t:any) => ({
                        label: t.name,
                        value: t.id
                    }));
                    item.loading = false;
                    callback();
                });
            } else {
                request(`/device/device/?format=json&format=json&devicetype=${ item.value }&pagesize=999&room__floor=${ this.floorId }&room=${ this.roomId }`).then(({ data }) => {
                    item.children = data.results.map((t:any) => ({
                        label: t.serialnumber,
                        value: t.id
                    }));
                    item.loading = false;
                    callback();
                });
            }
        }
		mounted () {
			request("/prj/facilityfloorrooms/").then(({ data }) => {
				this.floorTree = [ data.data[ 0 ] ];
			});
			request("/repair/rest/requirement/").then(({ data }) => {
				this.resolveSource = data;
			});
            this.getDeviceType();
            if (this.myspacedata && this.myspacedata[0].apart[0]) {
                let temp = this.myspacedata[0].apart[0];
                if (temp.space.length === 3) {
                    this.floorId = temp.space[1];
                    this.roomId = temp.space[2];
                }
                this.info = {
                    how: temp.how,
                    why: temp.why,
                    space: temp.space,
                    device: temp.devicetype == 11542 ? [temp.devicetype, temp.camera] : [temp.devicetype, temp.deivce ? temp.deivce[0] : ""],
                    deadline: temp.deadline ? moment(temp.deadline * 1000).format("YYYY-MM-DD") : "",
                    priority: temp.priority,
                    describe: temp.description,
                    fileList: [],
                    template: ""
                };
            }
            // console.log(this.info, "7985", this.myspacedata);
		}
        // @Watch("myspacedata")
        // onmyspacedatachange () {
        //     console.log(this.info, "7985", this.myspacedata);
        // }
		beforeRouteLeave (to:Route, from:Route, next:Function) {
			// this.listener();
			next();
		}
	}

